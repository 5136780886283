import { MyIntegerEnum } from './enum'

export const TipoMovimentacao = {
  Entrada: MyIntegerEnum.create(0, 'Entrada'),
  Saida: MyIntegerEnum.create(1, 'Saída'),
}

export const FormaPagamento = {
  Avista: MyIntegerEnum.create(0, 'À vista'),
  Convenio: MyIntegerEnum.create(1, 'Convênio'),
  Saldo: MyIntegerEnum.create(2, 'Saldo'),
}

export const Status = {
  Aberto: MyIntegerEnum.create(0, 'Aberto'),
  Cancelado: MyIntegerEnum.create(1, 'Cancelado'),
  Faturado: MyIntegerEnum.create(2, 'Faturado'),
  Pago: MyIntegerEnum.create(3, 'Pago'),
}

export function tipoMovimentacaoToString(tipoMovimentacaoID) {
  switch (Number(tipoMovimentacaoID)) {
    case 0:
      return TipoMovimentacao.Entrada.name
    case 1:
      return TipoMovimentacao.Saida.name
  }
}

export function formaPagamentoToString(formaPagamentoID) {
  switch (Number(formaPagamentoID)) {
    case 0:
      return FormaPagamento.Avista.name
    case 1:
      return FormaPagamento.Convenio.name
    case 2:
      return FormaPagamento.Saldo.name
  }
}

export function statusToString(statusID) {
  switch (Number(statusID)) {
    case 0:
      return Status.Aberto.name
    case 1:
      return Status.Cancelado.name
    case 2:
      return Status.Faturado.name
    case 3:
      return Status.Pago.name
  }
}
