import axios from 'axios'
class MyIntegerEnum {
  constructor(id, name) {
    this._id = id
    this._name = name
  }

  get id() {
    return this._id
  }

  get name() {
    return this._name
  }

  static create(id, name) {
    return new this(id, name)
  }
}
const getUrl = (path, params) => {
  const uri = axios.getUri({
    url: path,
    params,
  })
  const base = axios.defaults.baseURL
  const slash = base.slice(-1) == '/' ? '' : '/'
  const url = base + slash + uri
  return url
}
const downloadUrl = async (url, filename = 'download.xlsx', config = {}) => {
  config['responseType'] = 'blob'
  try {
    const { data } = await axios.get(url, config)
    const _url = URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = _url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
  } catch (error) {
    return Promise.reject(error)
  }
}
/**
 * Limpa um objeto JSON, removendo chaves que contêm valores nulos, objetos vazios ou entradas vazias.
 *
 * @param {Object} input - O objeto JSON de entrada a ser limpo.
 * @returns {Object|null} - Um novo objeto JSON limpo ou null se não houver entradas válidas.
 *
 * A função percorre recursivamente o objeto de entrada. Se um valor for um objeto, a função será chamada
 * novamente para esse objeto. Se um valor for nulo ou um objeto vazio, ele será removido do resultado.
 * Valores que são strings vazias, números ou outros tipos não nulos são mantidos.
 * Exemplo:
 * input: {
 *     "a": { "b": { "c": "12", "d": "" } },
 *     "aa": { "bb": { } },
 *     "aaa": {}
 * };
 * output: {
 *   "a": { "b": { "c": "12" } }
 * }
 */
function cleanJson(input) {
  if (typeof input !== 'object' || input === null) {
    return input || null
  }
  const cleaned = {}
  for (const key in input) {
    if (Object.prototype.hasOwnProperty.call(input, key)) {
      const value = cleanJson(input[key])
      // Adiciona ao objeto apenas se value não for nulo ou um objeto vazio
      if (
        value !== null &&
        (typeof value === 'object' ? Object.keys(value).length > 0 : true)
      ) {
        cleaned[key] = value
      }
    }
  }

  return Object.keys(cleaned).length > 0 ? cleaned : null
}
export { MyIntegerEnum, downloadUrl, getUrl, cleanJson }
