class MyIntegerEnum {
  constructor(id, name) {
    this._id = id
    this._name = name
  }

  get id() {
    return this._id
  }

  get name() {
    return this._name
  }

  static create(id, name) {
    return new this(id, name)
  }
}
const getById = (obj, id) => {
  const entrie = Object.entries(obj).filter((i) => i[1].id == id)
  if (entrie.length > 0) {
    return entrie[0][1]
  }
  return null
}
const getNameById = (obj, id) => {
  const entrie = getById(obj, id)
  if (entrie && entrie.name) {
    return entrie.name
  }
  return ''
}

export { MyIntegerEnum, getById, getNameById }
